.checkout-wrapper {
  position: relative;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product-item-container {
  gap: 1rem;
  padding: 0.875rem 0;
}

.hidden {
  display: none !important;
}

.product-image-cart {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.text-total-price-value {
  text-align: right;
  font-weight: 500;
}

.order-now-button,
.order-pending-button,
.back-to-cart-button {
  display: inline-block;
  margin: 0 0 1rem;
  padding: 12px 20px;
  background-color: #e9a343;
  color: #173050;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.order-pending-button {
  background-color: white;
  color: #6c6c6c;
}

.back-to-cart-button {
  background-color: white;
  border: 1px solid #173050;
}

.checkout-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 45%;
  height: 100vh;
  background-color: #173050;
  position: sticky;
  top: 0;
  color: #fff;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.location-address {
  font-size: 14px;
  font-weight: 450;
  font-style: italic;
  margin: 5px 0 10px;
  padding-left: 10px;
  color: rgba(23, 48, 80, 1);
}

.cart-items-summary {
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.cart-summary {
  display: flex;
  max-width: 500px;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 2rem 0;
}

.checkout-section {
  width: 100%;
  flex: 1 1 55%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.checkout-section::-webkit-scrollbar {
  display: none;
}

.apply-discount-group .subtotal div {
  font-size: 16px;
}

.guest-checkout-prompt {
  text-align: center;
}

.checkout-nav {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: space-around;
  margin: 0rem 6rem;
}

.checkout-nav a {
  font-weight: lighter;
  text-decoration: none;
  padding: 1rem 3rem;
}

.checkout-nav a.active {
  font-weight: bold;
  text-decoration: none;
}

.checkout-link {
  font-weight: bold;
}

.checkout-section h2 {
  text-align: left;
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contact-container-login {
  text-align: center;
  border: none;
  background-color: rgba(23, 48, 80, 1);
  padding: 0.35rem 1rem;
  color: white;
  border-radius: 0.5rem;
}

.cart-summary h2 {
  text-align: left;
}

.form-input {
  padding: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

fieldset {
  display: flex;
  flex-direction: column;
  border: 0.5px solid;
}

legend {
  font-weight: 600;
}

.checkout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-header {
  padding-left: 4rem;
  padding-right: 4rem;
}

.checkout-form {
  padding: 2rem 1rem;
  margin: auto;
  max-width: 960px;
  width: 70%;
}

.news-offers {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 2rem 1rem;
  margin: auto;
}

.express-checkout {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1rem;
}

.express-checkout button {
  flex-grow: 1;
}

.customer-info-form,
.shipping-address-form,
.billing-address-form,
.payment-info-form,
.pickup-store-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Adjust based on your preference */
  margin: auto;
}

#discount-code {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.express-checkout-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  /* Adjust based on your preference */
  margin: auto;
  margin-bottom: 2rem;
}

.checkout-header-fieldset {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  padding-right: 2.5rem;
}

.customer-info-fieldset,
.shipping-address-fieldset,
.billing-address-fieldset,
.payment-info-fieldset,
.discount-code-info-fieldset,
.pickup-store-fieldset {
  border: 1px solid #ccc;
  padding: 1.5rem;
  margin-bottom: 2rem;
  gap: 1rem;
}

.form-group-checkout {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1 1 auto;
}

.form-group-checkout-subtotal {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 1rem;
}

.form-group-options {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 5px;
}

.text-item-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-control {
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  /* Ensure input fields take up full width of the form group */
  box-sizing: border-box;
}
.form-control:disabled {
  background-color: #4c7ebc50;
  color: #4c7ebc;
  border-color: #4c7ebc;
  cursor: not-allowed;
}

label {
  font-weight: bold;
}
label.required:after {
  content: "*";
  margin-left: 0.5em;
  color: #ac2825;
}

.guest-options-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin: auto;
}

.guest-options-fieldset {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

.checkbox-group {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
  margin-bottom: 1rem;
}

.apply-discount-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.checkout-summary-value {
  font-size: 16px;
  font-weight: 500;
}

.checkout-summary-label {
  font-size: 16px;
  font-weight: 400;
}

.checkout-summary-value-subtotal {
  font-size: 22px;
  font-weight: 600;
}

.checkout-summary-label-subtotal {
  font-size: 22px;
  font-weight: 500;
}

.checkout-summary-total-calculation-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.apply-discount-group button {
  text-align: center;
  color: #173050;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.1s ease;
}
.apply-discount-group button.active {
  background-color: #e9a343;
  box-shadow:
    0 0 10px #e9a343,
    inset 0 0 6px #ffffff70;
  color: #173050;
}
.apply-discount-group button.active:active {
  transform: scale(0.95);
}
.response-messages {
  margin: 0 0 1rem;
  font-size: 14px;
  font-weight: 450;
  color: #e9a343;
}

.checkout-login-button {
  align-self: center;
  padding: 0.5rem 0rem;
  margin: 1rem 0rem;
  width: 30%;
}

.name-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2rem;
}

.name-container .form-group {
  flex: 50%;
}

.name-container .form-group:first-child {
  margin-right: 10px;
}

.name-container .form-group:last-child {
  margin-left: 10px;
}

.discount-code-info-form {
  margin-bottom: 3rem;
}

/* signin popup */
.checkout-login-title {
  font-family: "pulpo-rust-100", serif;
  color: #173050;
  margin: 0 0 2rem !important;
}

.checkout-login-content-body .form-group {
  width: 100%;
  flex: 0 0 auto;
}

.popup-content .form-input {
  width: 100%;
  padding: 20px 8px 8px;
  border: 1px solid #ccc;
  border-radius: 0;
}

.checkout-login-container {
  min-width: 200px;
  max-width: 350px;
  margin: 0 auto;
}

.checkout-login-content-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout-login-form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.checkout-login-form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkout-login-form-label {
  display: block;
  margin-bottom: 5px;
}

.checkout-login-form-button {
  width: 100%;
  margin: 1rem 0;
  padding: 0.875rem;
  background-color: #173050;
  color: white;
  border: 1px solid #173050;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
}

.checkout-login-form-button:hover {
  background-color: #fff;
  color: #173050;
}

.popup-container {
  background: #173050;
}

.modal {
  background-color: rgba(255, 255, 255, 1);
  width: 400px;
  padding: 2rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-buttons button {
  background-color: #173050;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif;
  font-weight: 450;
}

.customer-account-button {
  display: inline-block;
  padding: 12px 20px;
  background-color: white;
  color: #173050;
  border: none;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.header-buttons button.customer-signout-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 8px 8px;
  background-color: #173050;
  border: none;
  border-radius: 100%;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.popup-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
}

@media only screen and (max-width: 768px) {
  .checkout-wrapper {
    flex-direction: column;
  }

  .checkout-sidebar {
    width: 100%;
  }

  .cart-summary {
    padding-top: 0;
  }

  .modal {
    width: 300px;
  }
}

.stdropdown-container {
  text-align: left;
  /* border: 1px solid #ccc; */
  position: relative;
  border-radius: 5px;
}

.stdropdown-menu::-webkit-scrollbar {
  width: 2px;
}

.stdropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.stdropdown-menu::-webkit-scrollbar-thumb {
  background: #888;
}

.stdropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.stdropdown-input {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.stdropdown-menu {
  position: absolute;
  transform: translateY(4px);
  width: 100%;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  overflow: auto;
  max-height: 150px;
  background-color: #fff;
  z-index: 99;
}

.stdropdown-item {
  padding: 5px;
  cursor: pointer;
}

.stdropdown-item:hover {
  background-color: rgba(159, 195, 248, 0.4392156863);
}

.stdropdown-item.selected {
  background-color: #0d6efd;
  color: #fff;
}

.stdropdown-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.stdropdown-tag-item {
  background-color: #ddd;
  padding: 2px 4px;
  /* border-radius: 2px; */
  display: flex;
  align-items: center;
}

.stdropdown-tag-close {
  display: flex;
  align-items: center;
}

.stsearch-box {
  padding: 5px;
  background-color: transparent;
}

.stsearch-box input {
  width: 100%;
  /* box-sizing: border-box; */
  padding: 5px;
  /* border: 1px solid #ccc; */
  /* border-radius: 5px; */
}

.error {
  border: 2px solid red;
}

.order_notes {
  padding-left: 15px;
}

@media screen and (max-width: 768px) {
  .checkout-form {
    width: auto;
  }
}
