.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner {
  position: relative;
  max-width: 530px;
  max-height: 730px;
  height: 95%;
  background: #ffffff;
  padding: 25px;
  box-sizing: border-box;
  background-color: #fafaf5;
  animation: showup 0.5s ease;
  overflow: auto;
}
@keyframes showup {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.popup-wrapper {
  border: 2px solid #173050;
  background-color: #fff;
  box-shadow: 3px 3px 0 rgba(23, 48, 80, 0.15);
}

.popup-logo {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 30px 0;
}
.popup-logo img {
  width: 135px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  font-size: 1.8em;
  font-weight: 300;
  line-height: 0.6;
  padding: 5px;
  cursor: pointer;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #e9a343;
  margin: 20px;
  color: #173050;
}
.popup-content p {
  margin: 0.8rem 0;
  font-weight: 500;
}

.popup-content .popup-content-sale {
  font-family: "pulpo-rust-100", serif;
  font-size: 4rem;
  line-height: 0.8;
}

.popup-content h2 {
  margin-bottom: 20px;
}

.popup-input {
  width: 80%;
  position: relative;
  margin-bottom: 10px;
}
.popup-input .invalid-email-msg {
  position: absolute;
  bottom: -120%;
  left: 0;
  color: #173050;
  padding: 10px;
  background-color: #fff;
  font-size: 0.8rem;
  border: 1px solid #565656;
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.popup-input .invalid-email-msg::after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 9px;
  height: 9px;
  top: -6px;
  left: 10%;
  border: 1px solid #565656;
  border-right: none;
  border-bottom: none;
  transform: rotate(45deg);
}

.popup-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  outline: none;
}
.popup-input input.empty {
  border: 1px solid #ac2825;
}
.popup-input input:focus {
  border: 1px solid #173050;
}

.popup-signup-button {
  width: 80%;
  padding: 10px;
  background-color: #e9a343;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif;
}

.popup-signup-button:hover {
  background-color: #173050;
}

.popup-image {
  display: flex;
  width: 100%;
  gap: 5px;
}
.popup-image-container {
  flex: 1 1 50%;
  aspect-ratio: 6/5;
  background-position: center 30%;
  background-size: cover;
}

.popup-loading {
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 320px;
  height: 200px;
  background-color: #fff;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  .popup-inner {
    scale: 0.9;
  }
}
@media only screen and (max-width: 425px) {
  .popup-content .popup-content-sale {
    font-size: 3.5rem;
  }
  .popup-inner {
    padding: 20px;
  }
}
