.banner {
  /* padding-top: 720px; */
  height: 100vh;
  position: relative;
  transition: 0.3s ease;
}

.banner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../images/banner.jpg) no-repeat center / cover;
  will-change: transform;
}

.banner-content {
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  text-align: center;
  position: absolute;
  top: calc(50% + 30px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.banner-logo {
  opacity: 0;
  animation-delay: 0.3s !important;
}

.banner-content img {
  width: 120px;
  height: 120px;
}

.banner-text1,
.banner-text2,
.banner-text3 {
  opacity: 0;
  color: white;
  margin: 0;
  font-size: 36px;
  font-weight: 500;
  text-shadow: 2px 1px 6px rgba(23, 48, 80, 1);
  animation-delay: 0.5s !important;
}

.banner-text1 {
  font-size: 32px;
  color: #ac2825;
  font-family: "pulpo-rust-100", serif;
}

.banner-text3 {
  font-size: 28px;
  font-weight: 450;
  animation-delay: 0.8s !important;
}

.banner-button {
  opacity: 0;
  background-color: #e9a343;
  color: white;
  letter-spacing: 2px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  transition: 0.3s ease;
  text-decoration: none;
  animation-delay: 1.1s !important;
}

.banner-button:hover {
  background-color: #173050;
}

.fade-in {
  display: block;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.slide-up {
  display: block;
  animation: slideUp 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1023px) {
  .banner {
    height: 587px;
  }
}

@media (max-width: 768px) {
  .banner-content img {
    width: 100px;
    height: 100px;
  }

  .banner {
    height: 485px;
  }

  .banner-text2,
  .banner-text3 {
    font-size: 28px;
  }

  .banner-text3 {
    font-size: 18px;
  }
  .banner-button {
    font-size: 16px;
    padding: 10px 15px;
  }
}
