.blog-list-title,
.blog-list-filter,
.blog-list-container {
  max-width: 1350px;
  margin: 20px auto;
  padding: 0 25px;
}

.blog-list-title {
  overflow: hidden;
  font-weight: 500;
  color: #565656;
  margin-top: 137px;
}

.blog-list-title h2 {
  font-size: 40px;
  color: #173050;
  font-family: "pulpo-rust-100", serif;
  font-weight: normal;
  margin: 30px 0 0;
}

.blog-list-title p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 450;
  color: #000;
}

.blog-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.blog-container-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border: 1px solid rgba(128, 128, 128, 0.16);
  position: relative;
  height: 120px;
  transition: 0.3s ease;
  padding: 250px 20px 20px;
  box-shadow: 0 1px 4px rgba(128, 128, 128, 0.11);
  text-decoration: none;
}

.blog-container-list:hover {
  box-shadow: 0 2px 8px rgba(128, 128, 128, 0.2);
  border-color: #173050;
}

.blog-container-list:hover .blog-image {
  transform: scale(1.1);
}

.blog-container-list:hover .blog-info-title {
  color: #4c7ebc;
}

.blog-container-list a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: 450;
  transition: 0.3s ease;
}
.blog-container-list:hover a {
  color: #173050;
}
.blog-container-list svg {
  min-width: 20px;
  width: 20px;
  height: 20px;
  transform: translateX(-10px);
  opacity: 0;
  transition:
    0.3s ease,
    opacity 0.3s ease;
}
.blog-container-list:hover svg {
  transform: translateX(10px);
  opacity: 1;
}

.blog-image-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 230px;
  overflow: hidden;
}

.blog-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  transition: 0.3s ease;
}

.blog-info-title {
  text-decoration: none;
  font-size: 20px;
  text-transform: capitalize;
  font-family: "pulpo-rust-100", serif;
  color: #173050;
  transition: 0.3s ease;
}

@media (max-width: 576px) {
  .blog-list-title {
    margin-top: 80px;
  }
  .blog-list-title h2 {
    font-size: 32px;
  }
  .blog-list-title p {
    font-size: 14px;
  }
}
