.feature-banner {
  background-size: cover;
  background-position: center;
}
.feature-banner-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 1350px;
  margin: auto;
  padding: 25px;
}
.feature-banner-content {
  display: flex;
  margin: 3rem 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.feature-banner-logo {
  width: 150px;
}
.feature-banner-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #e9a343;
  margin: 0.6rem 0;
}
.feature-banner-product-name {
  font-size: 4rem;
  font-weight: 600;
  color: #fff;
  margin: 0;
  text-align: center;
}
.feature-banner-description {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin: 0.8rem 0;
}
.feature-banner-button {
  margin-top: 1rem;
  display: block;
  border: 1px solid #e9a343;
  color: #e9a343;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;
}
.feature-banner-button:hover {
  background-color: #e9a343;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .feature-banner-wrapper {
    justify-content: center !important;
  }
  .feature-banner-product-name {
    font-size: 2rem;
  }
}
