.blog-detail-wrapper,
.blog-pageturn {
  max-width: 910px;
  padding: 0 25px;
  margin: 117px auto 30px;
  overflow: hidden;
}

.blog-detail-info,
.blog-detail-image,
.blog-content-abs,
.blog-content-body {
  margin: 0 0 20px;
}

.blog-detail-image .detail-image {
  width: 100%;
  background-size: cover;
  background-position: center;
  aspect-ratio: 16/9;
}

.blog-detail-title {
  margin-top: 0;
  margin-bottom: 25px;
  color: #173050;
  font-size: 42px;
  text-transform: capitalize;
  word-break: break-word;
  font-family: "pulpo-rust-100", serif;
  font-weight: normal;
}

.blog-detail-grid {
  display: flex;
  flex-wrap: wrap;
}
.blog-detail-grid .detail-grid-item {
  flex: 1;
  flex-basis: auto;
  flex-grow: 0;
  padding-bottom: 25px;
  margin-right: 30px;
}
.blog-detail-grid .detail-grid-item:last-child {
  margin-right: 0;
  margin: 0 0 0 auto;
}
.blog-detail-grid .detail-grid-item .detail-social-icons a {
  font-size: 16px;
}
.blog-detail-grid .detail-grid-item:first-child p {
  color: #173050;
  font-weight: 500;
}
.detail-grid-item h3 {
  margin: 0 0 10px;
  padding: 0 0 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #333;
  border-bottom: 1px solid #ddd;
}
.blog-detail-grid .detail-grid-item p {
  margin: 0;
  font-size: 16px;
  font-weight: 450;
  color: #333;
}
.blog-content-abs {
  padding: 0 25px;
  color: #173050;
  background-color: #fafaf5;
  border-left: 2px solid #173050;
  font-size: 18px;
  font-weight: 500;
  overflow: hidden;
}

.blog-content-body {
  font-size: 16px;
  font-weight: 450;
  line-height: 1.5;
}

.blog-content-body img {
  width: 100%;
  height: auto;
}
.content-body-section h3 {
  font-size: 36px;
  color: #173050;
  font-family: "pulpo-rust-100", serif;
  font-weight: normal;
}

.blog-pageturn {
  padding: 0 25px;
  margin-bottom: 30px;
}
.blog-pageturn::after {
  content: "";
  display: block;
  clear: both;
}
.blog-pageturn a {
  max-width: 36%;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #173050;
  text-decoration: none;
  font-family: "pulpo-rust-100", serif;
  transition: 0.3s ease;
}
.blog-pageturn a:hover {
  color: #4c7ebc;
}
.blog-pageturn a span {
  padding-top: 5px;
  text-transform: capitalize;
  font-weight: 450;
  font-family: "Montserrat", sans-serif;
  color: #333;
}
.blog-pageturn a svg {
  font-size: 13px;
  position: absolute;
  top: 4px;
}

.blog-pageturn a.turn-button-right svg {
  right: -15px;
}
.blog-pageturn a.turn-button-left svg {
  left: -15px;
}
.blog-pageturn a.turn-button-left {
  float: left;
}
.blog-pageturn a.turn-button-right {
  float: right;
  text-align: right;
}

@media only screen and (max-width: 1023px) {
  .blog-detail-title {
    font-size: 36px;
  }
  .content-body-section h3 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 576px) {
  .blog-detail-title {
    font-size: 32px;
  }
  .content-body-section h3 {
    font-size: 28px;
  }
}
