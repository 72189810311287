.fest-info {
  background-color: #fef9e6;
}

.fest-info-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 127px 25px 0;
}

.fest-info-banner h2 {
  width: 230px;
  margin: 0;
}

.fest-info-wrapper {
  position: relative;
  max-width: 720px;
  padding: 0 25px;
  margin: 117px auto 150px;
}

.fest-info-wrapper h3 {
  font-size: 34px;
  color: #e35f0b;
  text-align: center;
  margin: 0 0 30px;
  /* font-family: 'pulpo-rust-100', serif; */
}

.fest-info-wrapper h4 {
  color: #e35f0b;
  font-size: 24px;
  margin: 20px 0;
}

.fest-info-wrapper {
  font-size: 18px;
  font-weight: 450;
  color: #000;
}

.fest-info-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 20px 0;
  text-decoration: none;
  font-weight: 500;
  color: #fff;
  background-color: #173050;
}
.fest-info-footer {
  height: 200px;
  background-color: #fdb813;
  position: relative;
}

.fest-info-footer::before {
  content: "";
  position: absolute;
  top: -140px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 770px;
  height: 200px;
  background: url(../images/fest_info_goldenfields.png) no-repeat center 100% /
    100%;
}

@media only screen and (max-width: 768px) {
  .fest-info-footer::before {
    top: -140px;
    height: 200px;
  }
}

@media only screen and (max-width: 567px) {
  .fest-info-footer::before {
    top: -120px;
    height: 120px;
  }
}
