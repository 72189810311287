.contest-wrapper {
  display: flex;
  justify-content: space-around;
  max-width: 1350px;
  margin: 120px auto 50px;
  padding: 25px;
  /* border: 1px solid #173050; */
  gap: 4%;
}

.contest-info,
.contest-form {
  flex: 50%;
  padding: 1rem;
}

.contest-info {
  border: 1px solid #173050;
}

.contest-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6%;
  align-items: center;
  text-align: center;
  color: #173050;
}

.contest-form h1 {
  padding: 1.5rem 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -10px;
}

.contest-banner-image {
  padding-top: 50%;
  background: no-repeat center center / cover;
}

.contest-content {
  padding: 2.5rem 2rem 1.5rem;
  font-size: 1.125rem;
  color: #173050;
  font-weight: 450;
  text-align: center;
}

.contest-title {
  font-family: "pulpo-rust-100", serif;
  margin: 0;
  color: #173050;
  font-size: 2.25rem;
}

.contest-subtitle {
  color: #ac2825;
  font-size: 1.5rem;
  margin: 0 0 1.5rem;
}

.contest-link {
  color: #173050;
  font-weight: 600;
}
.contest-link:hover {
  color: #ac2825;
}

.contest-input {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1rem auto;
  width: 80%;
}

.contest-input-item {
  position: relative;
}

.contest-input-item input,
.contest-input-item select {
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 0.5rem;
  outline: none;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.invalid-email-msg {
  position: absolute;
  bottom: -120%;
  left: 0;
  color: #173050;
  padding: 10px;
  background-color: #fff;
  font-size: 0.8rem;
  border: 1px solid #565656;
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  animation: fadeIn 0.3s ease;
  z-index: 1;
}
.invalid-email-msg::after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 9px;
  height: 9px;
  top: -6px;
  left: 10%;
  border: 1px solid #565656;
  border-right: none;
  border-bottom: none;
  transform: rotate(45deg);
}

.contest-input-item input.empty {
  border: 1px solid #ac2825;
}

.contest-input-item input:hover,
.contest-input-item select:hover,
.contest-input-item input:focus,
.contest-input-item select:focus {
  border: 1px solid #173050;
}

.contest-button {
  width: 80%;
  border-radius: 0;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  padding: 0.8rem 0.5rem;
  border: 1px solid #173050;
  background: #173050;
  color: #fff;
  cursor: pointer;
}

.contest-rules {
  width: 80%;
  margin: auto;
  text-align: left;
  font-size: 0.875rem;
}

.contest-rules a {
  color: #173050;
  font-weight: 500;
}

.contest-loading {
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 320px;
  height: 200px;
  background-color: #fff;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  .contest-wrapper {
    gap: 2rem;
  }

  .contest-title {
    font-size: 2rem;
  }

  .contest-subtitle {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 768px) {
  .contest-wrapper {
    flex-direction: column;
    gap: 0;
  }

  .contest-title {
    font-size: 2rem;
  }

  .contest-subtitle {
    font-size: 1.25rem;
  }

  .contest-info {
    border: none;
    padding: 0;
  }
  .contest-form {
    padding: 0;
  }

  .contest-content {
    padding: 2.5rem 1rem 0;
  }

  .contest-form h1 {
    margin-top: 0;
    padding: 0 0 1.5rem;
  }
}
