.terms-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../images/FMS_images-23-scaled.webp) no-repeat center
    center/cover;
  padding: 127px 25px 40px;
  height: 240px;
  position: relative;
}

.terms-banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.terms-banner h2 {
  color: #fff;
  z-index: 10;
  font-size: 40px;
  font-weight: normal;
  font-family: pulpo-rust-100, serif;
}
.terms-wrapper:first-child {
  margin-top: 137px;
}
.terms-wrapper {
  max-width: 910px;
  padding: 0 25px;
  margin: 117px auto 70px;
  overflow: hidden;
}

.terms-body {
  font-size: 16px;
  margin: 0 0 20px;
  font-weight: 450;
  line-height: 1.5;
}

.terms-body-section {
  font-size: 18px;
}

.terms-body-section-form {
  max-width: 600px;
  margin: 0 auto;
}
.terms-body-section-form input,
.terms-body-section-form textarea {
  width: 100%;
  padding: 10px;
  margin: 0 0 10px;
  border: 1px solid #ccc;
  border-radius: 1px;
  font-size: 16px;
  font-weight: 450;
  box-sizing: border-box;
  outline-color: #173050;
}

.terms-body-section-form label {
  font-weight: 600;
}

.terms-body-section-form label::after {
  content: "*";
  color: #ac2825;
}

.terms-body-section-form button {
  background-color: #173050;
  color: #fff;
  border: 1px solid #173050;
  border-radius: 1px;
  padding: 13px 20px;
  margin-top: 2rem;
  width: 100%;
  transition: all 0.3s ease;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}
.terms-body-section-form button:hover {
  background-color: #fff;
  color: #173050;
}

.form-group {
  flex-direction: column;
}

.terms-body-section h3 {
  font-size: 32px;
  color: #333;
  text-align: center;
}
.terms-body-section h4 {
  font-size: 20px;
  margin: 0.5em 0;
}
.terms-body-section h2 {
  font-size: 2rem;
  margin: 1rem 0;
  color: #173050;
  font-family: "pulpo-rust-100", serif;
}
.terms-body-section a {
  color: #173050;
}

.content-center {
  text-align: center;
}

.aboutus-banner {
  height: 600px;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.aboutus-banner-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 1350px;
  height: 100%;
  padding: 0 25px;
  margin: auto;
}
.aboutus-banner-content {
  max-width: 504px;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 36px;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.aboutus-banner-content h2 {
  font-family: "pulpo-rust-100", serif;
  font-size: 32px;
  color: #ac2825;
  margin: 0 0 1rem;
}

.aboutus-banner-content p {
  font-size: 1rem;
  font-weight: 450;
  color: #173050;
  line-height: 1.5;
}

.aboutus-banner-content-button {
  display: inline-block;
  background-color: #e9a343;
  color: white;
  border: none;
  padding: 10px 20px;
  transition: background 0.3s ease;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: 500;
}

.aboutus-banner-content-button:hover {
  background-color: #173050;
}

.aboutus-section {
  display: flex;
  gap: 30px;
  padding-top: 70px;
  justify-content: space-between;
}

.aboutus-section-image {
  display: flex;
  justify-content: center;
  flex: 1 1 45%;
  min-height: 340px;
}
.aboutus-section-bio {
  flex: 1 1 55%;
  font-size: 18px;
}
.aboutus-section-image-bg {
  position: relative;
  width: 250px;
  height: 250px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: 10px solid #fff;
}
.aboutus-section-image-fg {
  position: absolute;
  bottom: -75px;
  right: -75px;
  width: 150px;
  height: 150px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: 10px solid #fff;
}

.aboutus-section-image-bg img,
.aboutus-section-image-fg img {
  width: 100%;
  height: 100%;
}

.aboutus-section-bio h3 {
  font-size: 32px;
  color: #173050;
  margin: 0 0 1rem;
  font-family: "pulpo-rust-100", serif;
}

.aboutus-section-bio p {
  font-weight: 450;
  color: #000;
  line-height: 1.5;
}

.aboutus-span {
  background: #173050;
}

.aboutus-span-wrapper {
  display: flex;
  max-width: 1350px;
  padding: 25px;
  margin: auto;
}

.aboutus-span-images {
  display: flex;
  gap: 15px;
  flex: 1 1 55%;
}
.aboutus-span-content {
  display: flex;
  gap: 10px;
  flex: 1 1 45%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.aboutus-span-content img {
  width: 35%;
}
.aboutus-span-content a {
  display: inline-block;
  font-size: 2rem;
  font-weight: 450;
  border: 1px solid #e9a343;
  color: #e9a343;
  text-decoration: none;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.aboutus-span-content a:hover {
  background: #e9a343;
  color: #fff;
}

.aboutus-span-content p {
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  margin: 0.5rem 0;
  line-height: 1;
}

.aboutus-span-content p.pulpo-rust {
  font-family: "pulpo-rust-100", serif;
  font-size: 1.75rem;
}

.aboutus-span-content p span::after {
  content: "";
  display: inline-block;
  height: 1.25rem;
  width: 1px;
  background: #e9a343;
  margin: 0 0.5rem;
}
.aboutus-span-content p span:last-child::after {
  display: none;
}

.aboutus-span-images .span-images {
  flex: 1 1 50%;
  aspect-ratio: 6/5;
  background: no-repeat center center/cover;
}

@media only screen and (max-width: 1023px) {
  .aboutus-section {
    flex-direction: column;
  }
  .aboutus-section-image {
    justify-content: center;
  }

  .terms-body-section h3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .aboutus-section-image {
    justify-content: flex-start;
  }
  .aboutus-banner-content h2 {
    font-size: 24px;
  }
  .aboutus-banner-content {
    text-align: center;
  }
  .aboutus-banner-content p {
    font-size: 14px;
  }
  .terms-banner {
    height: 120px;
  }
  .terms-banner h2 {
    font-size: 32px;
  }
  .terms-body-section h3 {
    font-size: 20px;
  }
  .terms-body-section h4 {
    font-size: 18px;
  }
  .aboutus-span-wrapper {
    flex-direction: column;
    gap: 25px;
  }
  .aboutus-span-content p {
    font-size: 1.25rem;
  }
  .aboutus-span-content p.pulpo-rust {
    font-size: 1.5rem;
  }
  .aboutus-span-content a {
    font-size: 1.5rem;
  }
}
