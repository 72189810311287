.promotion-banner {
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 150px 25px 40px;
  height: 240px;
  position: relative;
  transition: 0.3s ease;
}

.promotion-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.promotion-banner h1 {
  font-size: 36px;
  color: #fff;
  text-shadow: 2px 0 5px rgba(23, 48, 80, 0.5);
  font-family: "pulpo-rust-100", serif;
  font-weight: normal;
}

.promotion-banner img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  transition: all ease-in 1s;
}

.promotion-banner .regular-price {
  color: #fff;
  font-size: 20px;
}

.price-highlight {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
}

.see-detail-button {
  background-color: #e9a343;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  transition: 0.3s ease;
  font-weight: 500;
  text-decoration: none;
}

.see-detail-button:hover {
  background-color: #173050;
}

.filter-section {
  max-width: 1350px;
  text-align: right;
  margin: 20px auto;
  padding: 10px 25px;
}

.filter {
  font-size: 16px;
  display: inline-block;
  margin-right: 15px;
  color: #173050;
}

.filter span {
  font-weight: 500;
  margin-right: 5px;
}

.filter select {
  padding: 5px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #173050;
}

.filter-button {
  background-color: #173050;
  color: #fff;
  border: none;
  padding: 7px 17px;
  font-size: 14px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  transition: 0.3s ease;
}

.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1350px;
  margin: 20px auto;
  padding: 0 25px;
}

.products-container-list {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(128, 128, 128, 0.5);
  position: relative;
  min-height: 375px;
  transition: 0.3s ease;
  padding: 20px;
  box-shadow: 0 1px 6px rgba(128, 128, 128, 0.11);
}

.products-container-list:hover {
  box-shadow: 0 2px 8px rgba(128, 128, 128, 0.2);
  border-color: #173050;
}

.products-container-list:hover .product-list-purchase input {
  border-color: #173050;
}

.products-container-list:hover .product-list-cart {
  color: #fff;
  background-color: #173050;
}

.products-container-list:hover .product-list-cart.out-of-stock {
  background-color: #666;
  border-color: #666;
  cursor: default;
}

.product-image-wrapper {
  height: 200px;
  position: relative;
  overflow: hidden;
}

.product-image-wrapper .sale-label {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
  background: url(../images/Sale_icon.svg) no-repeat center / cover;
}
.product-image-wrapper .instore-label {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
  background: url(../images/Instore_icon.svg) no-repeat center / cover;
}
.product-image-wrapper .oneday-label {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
  background: url(../images/Oneday_icon.svg) no-repeat center / cover;
}

.product-image-wrapper .feature-label {
  display: block;
  width: 64px;
  height: 64px;
  left: 0;
  top: 0;
  position: absolute;
  background: url(../images/Feature_icon.svg) no-repeat center / cover;
}

.black-friday-label {
  --d: 0.8em; /* the depth */
  font-size: 18px;
  font-family: "pulpo-rust-100", serif;
  text-align: center;
  font-weight: bold;
  color: #e9a343;
  position: absolute;
  bottom: 15px;
  inset-inline: calc(-1 * var(--d));
  border-top: var(--d) solid #0008;
  line-height: 1.8;
  clip-path: polygon(
    0 100%,
    100% 100%,
    100% var(--d),
    calc(100% - var(--d)) 0%,
    calc(100% - var(--d)) var(--d),
    var(--d) var(--d),
    var(--d) 0%,
    0 var(--d)
  );
  background-color: #000; /* the main color */
}

.product-image.out-of-stock {
  filter: grayscale(90%);
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  transition: 0.3s ease;
  cursor: pointer;
}

.product-image:hover {
  transform: scale(1.1);
}

.price {
  color: #333;
  font-weight: 600;
  font-size: 20px;
}

.low-stock {
  margin-top: 10px;
  color: #ac2825;
  font-size: 0.875rem;
  font-weight: 500;
}

.regular-price {
  text-decoration: line-through;
  color: #333;
  margin-left: 10px;
  font-weight: 450;
  font-size: 14px;
}

.regular-price .price-uom {
  color: #333;
  font-weight: 450;
  margin-left: 0;
  text-decoration: line-through;
}

.price-uom {
  color: #173050;
  font-size: 14px;
  font-weight: 500;
}

.price-uom::before {
  content: " / ";
}
.promotion-banner .banner-content {
  height: auto;
  top: calc(50% + 80px);
}
.banner-content .price-uom {
  color: #fff;
  font-size: 20px;
}

.product-results-list-title {
  max-width: 1350px;
  margin: 20px auto;
  padding: 0 25px;
  overflow: hidden;
  font-weight: 500;
  color: #565656;
  margin-top: 137px;
}

.product-results-list-title h2 {
  font-size: 28px;
  font-weight: 500;
  color: #333;
  margin: 20px 0;
}

.product-list-title {
  display: block;
  min-height: 50px;
  max-height: 75px;
  font-size: 18px;
  margin: 20px 0 0;
  color: #173050;
  overflow: hidden;
  text-decoration: none;
  font-family: "pulpo-rust-100", serif;
  transition: 0.3s ease;
  cursor: pointer;
}

.product-list-price {
  margin-top: 15px;
}

.product-list-title:hover {
  color: #4c7ebc;
}

.product-list-purchase {
  margin-top: auto;
}
.product-list-incart {
  display: flex;
  justify-content: space-between;
}
.item-in-cart {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  color: #4c7ebc;
}

/* "add to cart" and "see detail" style */
.product-list-cart {
  width: 100%;
  color: #173050;
  background-color: #fff;
  border: 1px solid rgba(128, 128, 128, 0.5);
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-family: "Montserrat", sans-serif;
}

.product-list-cart.out-of-stock {
  color: #565656;
}
.product-list-cart.in-store-product {
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
}

.products-container-list .product-list-cart:hover {
  letter-spacing: 2px;
  border-color: #173050;
}
.products-container-list .product-list-cart.out-of-stock:hover {
  color: #fff;
}

.pagination-wrapper {
  max-width: 1350px;
  margin: 20px auto;
  padding: 0 25px;
}
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 1rem 0;
  gap: 1rem;
}

.pagination .page-link {
  color: #173050;
  background-color: #fff;
  border: 1px solid transparent;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  transition: 0.3s ease;
}
.pagination .page-link:hover {
  background-color: #173050;
  color: #fff;
  border-color: #173050;
}
.pagination .page-item.active .page-link {
  background-color: #173050;
  color: #fff;
  border-color: #173050;
}

.pagination .page-item.active:hover .page-link {
  background-color: #fff;
  color: #173050;
  border-color: #173050;
}

.pagination .page-item.disabled {
  display: none;
}
/* responsive design */
@media only screen and (max-width: 567px) {
  .promotion-banner h1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .product-results-list-title {
    margin-top: 100px;
  }
  .product-results-list-title h2 {
    font-size: 28px;
  }
}
