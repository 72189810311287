.store-selector-overlay {
  z-index: 1460;
  display: flex;
  justify-content: flex-end;
  background: rgba(38, 38, 38, 0.75);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.store-selector-overlay.sm {
  justify-content: center;
  align-items: center;
}

.store-selector-wrapper {
  width: 36rem;
  background-color: #fff;
  height: 100%;
  position: relative;
}

.store-selector-overlay.sm .store-selector-wrapper {
  height: auto;
  margin: 0 1rem;
}

.store-selector-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.store-selector-header {
  padding: 32px 40px 16px;
  box-shadow: 0 8px 24px rgba(38, 38, 38, 0.1019607843);
}

.store-selector-overlay.sm .store-selector-header {
  padding: 32px 40px 32px;
  box-shadow: none;
}

.store-selector-header p {
  font-size: 0.875rem;
  color: #ac2825;
}

.store-selector-overlay.sm .store-selector-header p {
  margin: 0;
  font-size: 1rem;
  font-weight: 450;
  color: #333;
}

.store-selector-header-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.store-selector-header-label h2 {
  font-size: 1.375rem;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.store-selector-overlay.sm .store-selector-header-label h2 {
  font-family: "pulpo-rust-100", serif;
  font-size: 1.75rem;
  color: #173050;
}

.store-selector-header-label button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.375rem;
  color: #000;
}

.store-selector-header-searchbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 16px;
}

.store-selector-overlay.sm .store-selector-header-searchbar {
  margin: 16px 0 0;
}

.store-selector-header-searchbar-container {
  position: relative;
  width: 85%;
}

.store-selector-header-searchbar-container input {
  width: 100%;
  height: 3rem;
  box-sizing: border-box;
  border: 1px solid #d4d4d4;
  border-radius: 1px;
  background: none;
  outline: none;
  padding: 6px 15px;
  color: #173050;
  background-color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 450;
}
.store-selector-header-searchbar-container span {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  color: #173050;
  font-size: 1.125rem;
  cursor: pointer;
}

.store-selector-header-searchbar-location {
  width: 3rem;
  height: 3rem;
  border: 1px solid #d4d4d4;
  background: none;
  font-size: 1.125rem;
  text-decoration: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease;
}

.store-selector-header-searchbar-location:hover {
  background: #173050;
  border: 1px solid #173050;
  color: #fff;
}

.store-selector-list-container {
  overflow-y: scroll;
}

.store-selector-overlay.sm .store-selector-list-container {
  overflow-y: auto;
}

.store-selector-list {
  padding: 0;
  margin: 0;
}

.store-selector-list-item {
  border-bottom: 1px solid #d4d4d4;
}

.store-selector-list-item-wrapper {
  padding: 24px 40px;
}

.store-selector-overlay.sm .store-selector-list-item-wrapper {
  padding: 16px 0;
}

.store-selector-list-name h3 {
  margin: 0 0 1rem;
  font-family: "pulpo-rust-100", serif;
  font-weight: 500;
  color: #173050;
}

.store-selector-list-details p {
  margin: 0 0 1rem;
  font-size: 1rem;
}

.store-selector-list-address {
  margin-bottom: 1rem;
}

.store-selector-list-address a {
  color: #333;
  font-size: 1rem;
  font-weight: 450;
}

.store-selector-list-address a:hover {
  color: #173050;
  font-weight: 500;
}

.store-selector-list-setdefault {
  margin-top: 1.5rem;
}

.store-selector-list-setdefault button {
  display: inline-block;
  background: #173050;
  border: 1px solid #173050;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.store-selector-list-setdefault button:hover {
  text-decoration: underline;
}

.store-selector-lis-select {
  width: 100%;
  font-family: "pulpo-rust-100", serif;
  color: #173050;
  font-size: 1.125rem;
  border-radius: 0;
  border: 1px solid rgba(128, 128, 128, 0.25);
  margin: 0 0 1rem;
  padding: 0.5rem 0.25rem;
  outline: none;
  box-shadow: 0 1px 6px rgba(128, 128, 128, 0.11);
}
