.form-container {
  background: url(../images/banner.jpg) no-repeat center center/ cover;
  height: 100vh;
}
.form-wrapper {
  display: flex;
  justify-content: center;
  padding: 222px 25px 0;
}

.form-content {
  max-width: 570px;
  padding: 40px;
  color: #173050;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-content-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.form-content-body {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.social-media-container {
  font-size: 24px;
  display: flex;
  justify-content: center;
}
.social-media-button {
  width: 30px;
  height: 30px;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  transition: 0.3s ease;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
}
.social-media-button:hover {
  background-color: rgba(23, 48, 80, 0.1);
}

.divider {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
}
.divider hr {
  flex: 1;
  margin: 0;
  border: 1px solid #173050;
  border-width: 0 0 1px;
  line-height: 0;
  height: 0;
}

.divider span {
  padding: 0 10px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  flex: 1 1 250px;
  position: relative;
}

.form-group .error-message {
  position: absolute;
  height: 20px;
  line-height: 20px;
  top: -20px;
  right: 0;
  padding: 0 7px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-color: rgba(172, 40, 37, 1);
}

.form-label {
  position: absolute;
  top: 16px;
  left: 8px;
  color: #565656;
  font-weight: 450;
  transition: 0.1s ease;
  pointer-events: none;
}

.form-input {
  width: 100%;
  height: 50px;
  padding: 20px 8px 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  outline: none;
}
.form-input:focus {
  border-color: #173050;
  box-shadow: 0 2px 8px rgba(128, 128, 128, 0.2);
}

.form-input:focus + .form-label {
  top: 5px;
  left: 8px;
  font-size: 12px;
  color: #565656;
}

.form-input.filled + .form-label {
  top: 5px;
  left: 8px;
  font-size: 12px;
  color: #565656;
}

.form-input.filled.error {
  border-color: #ac2825;
  color: #ac2825;
}

.form-input.filled.error + .form-label {
  color: #ac2825;
}

.form-button {
  width: 100%;
  padding: 15px;
  border: 1px solid #173050;
  background-color: #173050;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.form-button:hover {
  background-color: transparent;
  color: #173050;
}

.form-button.shake {
  animation: shake 0.3s ease;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
}

.form-link {
  color: #173050;
  font-weight: 450;
  font-size: 14px;
  text-decoration: underline;
}

@media only screen and (max-width: 576px) {
  .form-content {
    padding: 40px 20px;
  }
}
