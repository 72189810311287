.recipe-direct-wrapper::after {
  content: "Enjoy!";
  display: block;
  clear: both;
  font-size: 20px;
  margin-top: 30px;
}

.recipe-detail-wrapper,
.recipe-ingredt-wrapper,
.recipe-direct-wrapper,
.recipe-notes,
.recipe-pageturn,
.recipe-video {
  max-width: 1120px;
  padding: 0 25px;
  margin: 30px auto 0;
  overflow: hidden;
}

.recipe-detail-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 117px;
}

.recipe-detail-info,
.recipe-detail-image {
  flex: 1;
}

.recipe-detail-image {
  padding-left: 20px;
}
.detail-image {
  width: 100%;
  background-size: cover;
  background-position: center;
  aspect-ratio: 4/3;
}

.recipe-detail-title {
  margin-top: 0;
  color: #173050;
  font-size: 42px;
  text-transform: uppercase;
  word-break: break-word;
  font-family: "pulpo-rust-100", serif;
  font-weight: normal;
}

.recipe-detail-grid {
  display: flex;
  flex-wrap: wrap;
}
.detail-grid-item {
  flex: 1 1 40%;
  padding-bottom: 25px;
  margin-right: 10px;
}
.detail-grid-item h3 {
  margin: 0 0 10px;
  padding: 0 0 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #333;
  border-bottom: 1px solid #ddd;
}
.detail-grid-item p {
  margin: 0;
  font-size: 14px;
  font-weight: 450;
  color: #333;
}

.recipe-ingredt-wrapper p,
.recipe-direct-wrapper p {
  font-size: 16px;
  font-weight: 450;
  color: #333;
  margin: 15px 0;
}

.recipe-ingredt-wrapper h3,
.recipe-direct-wrapper h3 {
  font-size: 24px;
  font-family: "pulpo-rust-100", serif;
  font-weight: normal;
  letter-spacing: 1px;
  color: #173050;
  border-bottom: 1px solid #173050;
  line-height: 0;
  margin: 30px 0;
}
.recipe-ingredt-wrapper h3 span,
.recipe-direct-wrapper h3 span {
  background: #fff;
  padding-right: 10px;
}

.recipe-content {
  overflow: hidden;
}
.recipe-content h4 {
  text-transform: uppercase;
  margin: 20px 0;
}
.recipe-content ol,
.recipe-content ul {
  margin: 0;
  padding-right: 40px;
}

.recipe-content li:first-of-type p {
  margin-top: 0;
}
.recipe-content li:last-of-type p {
  margin-bottom: 0;
}

.recipe-notes p {
  color: #173050;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  text-align: center;
}
.recipe-notes-bio {
  overflow: hidden;
  margin: 20px 0;
  padding: 10px;
  font-weight: 450;
  background: #fafaf5;
  /* border-top: dashed 1px #173050; */
}
.recipe-notes-bio h4 {
  font-size: 32px;
  font-family: "pulpo-rust-100", serif;
  letter-spacing: 1px;
  color: #173050;
  padding: 10px 0;
  margin: 15px 0;
}
.recipe-notes-bio img {
  width: 150px;
  height: 150px;
  margin: 15px 30px 15px 15px;
  float: left;
}

.recipe-video {
  color: #173050;
  font-size: 20px;
  font-weight: bold;
  display: block;
  text-decoration: none;
  transition: 0.3s ease;
}
.recipe-video:hover {
  color: #4c7ebc;
  text-decoration: underline;
}

.recipe-pageturn {
  padding: 0 25px;
  margin-bottom: 30px;
}
.recipe-pageturn::after {
  content: "";
  display: block;
  clear: both;
}
.recipe-pageturn a {
  max-width: 36%;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #173050;
  text-decoration: none;
  font-family: "pulpo-rust-100", serif;
  transition: 0.3s ease;
}
.recipe-pageturn a:hover {
  color: #4c7ebc;
}
.recipe-pageturn a span {
  padding-top: 5px;
  text-transform: capitalize;
  font-weight: 450;
  font-family: "Montserrat", sans-serif;
  color: #333;
}
.recipe-pageturn a svg {
  font-size: 13px;
  position: absolute;
  top: 4px;
}

.recipe-pageturn a.turn-button-right svg {
  right: -15px;
}
.recipe-pageturn a.turn-button-left svg {
  left: -15px;
}
.recipe-pageturn a.turn-button-left {
  float: left;
}
.recipe-pageturn a.turn-button-right {
  float: right;
  text-align: right;
  align-items: flex-end;
}
.recipe-detail-media {
  margin-top: 10px;
}

.recipe-detail-grid .detail-social-icons {
  width: 100%;
  margin-top: 10px;
  justify-content: flex-end;
}

@media only screen and (max-width: 1023px) {
  .recipe-detail-title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .recipe-detail-wrapper {
    flex-direction: column;
  }
  .recipe-detail-image {
    padding-left: 0;
  }
  .detail-grid-item {
    flex: 1 1 20%;
  }
  .recipe-notes-bio {
    font-size: 14px;
  }
  .recipe-notes-bio h4 {
    font-size: 24px;
  }
  .recipe-ingredt-wrapper h3,
  .recipe-direct-wrapper h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .recipe-detail-title {
    font-size: 32px;
  }
  .recipe-notes-bio {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .recipe-notes-bio img {
    margin: 0 0 15px;
    float: none;
  }
  .recipe-notes p {
    font-size: 18px;
  }
  .recipe-notes-bio h4 {
    margin: 0;
  }
  .recipe-video {
    font-size: 16px;
  }
}
