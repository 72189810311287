.cart-empty,
.cart-loaded {
  max-width: 1350px;
  padding: 0 25px;
  margin: 137px auto 50px;
  overflow: hidden;
  gap: 1rem;
}

.cart-title {
  font-size: 40px;
  font-weight: normal;
  color: #173050;
  font-family: "pulpo-rust-100", serif;
  margin: 30px 0 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
}

.cart-content {
  min-height: 300px;
  margin: auto;
  padding: 0 25px;
  overflow: hidden;
}

.cart-empty .cart-content {
  margin: 30px auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #565656;
}

.cart-content h3 {
  color: #333;
  font-size: 20px;
  letter-spacing: 1px;
}

.cart-content p {
  text-align: center;
  font-weight: 450;
}

.cart-continue-button,
.cart-checkout-button {
  display: inline-block;
  padding: 12px 20px;
  background-color: #e9a343;
  color: #173050;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: center;
}
.cart-continue-button {
  border: 1px solid #173050;
  background-color: #fff;
}
.cart-checkout-button {
  border: 1px solid #e9a343;
}

.cart-continue-button:hover,
.cart-checkout-button:hover {
  background-color: #173050;
  color: #fff;
  cursor: pointer;
  border: 1px solid #173050;
}
@media only screen and (max-width: 768px) {
  .cart-content {
    padding: 0;
  }
}
@media (max-width: 576px) {
  .cart-title {
    font-size: 32px;
  }
}
