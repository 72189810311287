@import url("//cdn-images.mailchimp.com/embedcode/classic-061523.css");

#mc_embed_signup {
  background: #fff;
  clear: left;
  font:
    14px Helvetica,
    Arial,
    sans-serif;
  width: 600px;
}
